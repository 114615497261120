import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.css";

export default function highlightCode() {
  hljs.configure({ ignoreUnescapedHTML: true });

  setTimeout(() => {
    // Load it this way because line numbers checks for window.hljs
    window.hljs = hljs;
    require("highlightjs-line-numbers.js");
    hljs.initLineNumbersOnLoad();
  }, 1000);

  const codeBlocks = document.querySelectorAll("pre > code");
  codeBlocks.forEach((codeBlock) => {
    if (typeof codeBlock === "object") {
      hljs.highlightElement(codeBlock);
    }
  });
}
