import { Link, graphql } from "gatsby";
import React, { useEffect } from "react";
import Layout from "../layouts/layout";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import highlightCode from "../utils/highlightCode";

export default function Template({ data }) {
  const post = data.markdownRemark;

  useEffect(() => {
    highlightCode();
  });

  return (
    <Layout pageTitle={post.frontmatter.title}>
      <div>
        <div className="flex text-lg">
          <Link to="/" className="self-center">
            <MdOutlineKeyboardArrowLeft className="inline text-4xl text-blue-500 self-center" />
            Go back
          </Link>
        </div>
        <hr />
        <h1 className="text-3xl">{post.frontmatter.title}</h1>
        <p className="text-xs text-gray-500">
          Posted by {post.frontmatter.author} on {post.frontmatter.date}
        </p>
        <div
          className="blog-post-md m-4 shadow-lg p-4 border rounded"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </Layout>
  );
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date
        title
        description
        author
      }
    }
  }
`;
